import { Box, Button, Link, Stack, Text } from '@chakra-ui/react';
import process from 'process/browser';
import { useEffect, useState } from 'react';
import CourseAPI from '../../../../../api/Course';
import { Heading } from '../../../../../components/Heading';
import Toast from '../../../../../components/Toast';
import { useStudentCourse } from '../../../../../contexts/CourseStudentContext';
import { useProgressContext } from '../../../../../contexts/ProgressContext';
import { useTheme } from '../../../../../contexts/ThemeContext';
import ErrorResponse from '../../../../../helpers/ErrorResponse';
import { ID_LOCAL_STORAGE } from '../../../../../helpers/LocalStorageHelper';
import { scrollbarStyle } from '..';

const BASE_URL = process.env.REACT_APP_BASE_URL_API;

function ViewCertificate({ courseId }) {
  const { studentCourse } = useStudentCourse();
  const { progress } = useProgressContext();
  const studentId = localStorage.getItem(ID_LOCAL_STORAGE);
  const { themeMarketplace } = useTheme();

  const [certificateData, setCertificateData] = useState(null);

  const hideCertificate = progress.progress < studentCourse?.certificate?.emissionCondition;

  useEffect(() => {
    async function generateCertificate() {
      try {
        const { data: certificateData } = await CourseAPI.certificate.generate(courseId, studentId);

        if (certificateData) {
          setCertificateData(certificateData);
        }
      } catch (error) {
        Toast(ErrorResponse(error), 'error');
      }
    }

    generateCertificate();
  }, [courseId, studentId]);

  return (
    <Stack w="full" px={{ base: 4, xl: 8 }} overflow="auto">
      <Stack
        direction={{ base: 'column', md: 'row', lg: 'row' }}
        alignItems={{ base: 'center', md: 'baseline', lg: 'baseline' }}
        justifyContent={{ base: 'center', md: 'space-between', lg: 'space-between' }}
      >
        <Heading as="h3" fontSize={{ base: '3xl', md: '2xl', lg: '2xl' }} mt={4} p={0}>
          Certificado
        </Heading>

        <Button
          as={Link}
          href={`${BASE_URL}/certificate/${courseId}/course/${studentId}/user?download=true`}
          target="_blank"
          size="sm"
          width={{ base: 'full', md: 'auto', lg: 'auto' }}
          bgColor={themeMarketplace.primaryColor}
          color={themeMarketplace.textColor}
          _hover={{ bgColor: themeMarketplace.primaryColor.concat('50') }}
          padding={2}
          isDisabled={hideCertificate}
          style={
            hideCertificate
              ? {
                  pointerEvents: 'none',
                  textDecoration: 'none',
                }
              : {
                  pointerEvents: 'visible',
                  textDecoration: 'none',
                }
          }
        >
          Emitir certificado
        </Button>
      </Stack>

      {progress.progress < 100 && (
        <Box gridArea="description">
          <Text fontSize="xl" lineHeight="short" color="dark.500">
            Para emitir o certificado você precisa concluir{' '}
            <Text display="inline">
              {studentCourse?.certificate?.emissionCondition}% do conteúdo
            </Text>
            , continue progredindo no curso.
          </Text>
        </Box>
      )}

      <Box
        margin="0 auto"
        bg="#FFF"
        sx={scrollbarStyle}
        filter={hideCertificate && 'blur(0.5rem)'}
        dangerouslySetInnerHTML={{ __html: certificateData?.certificateTemplete }}
        aspectRatio={842 / 595}
        mb={6}
        w="full"
        height={{ base: 'full', xl: '595x' }}
      />
    </Stack>
  );
}

export default ViewCertificate;
