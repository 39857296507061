import { useColorModeValue } from '@chakra-ui/react';
import process from 'process/browser';
import { useCallback, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ExternalScripts from './ExternalScripts';
import NotFound from './NotFount';
import JivoChatIntegration from './api/JivoChatIntegration';
import Platform from './api/Platform';
import ChatBase from './components/ChatBase';
import WhatsAppButton from './components/WhatsAppButton';
import SearchContextProvider from './contexts/SearchContext';
import { useTheme } from './contexts/ThemeContext';
import { UTMProvider } from './contexts/UTMContext';
import {
  FIRST_ACCESS_LOCAL_STORAGE,
  ROLE_LOCAL_STORAGE,
  UTM_LOCAL_STORAGE,
} from './helpers/LocalStorageHelper';
import './helpers/dayjsPlugins';
import Blog from './pages/Blog';
import Post from './pages/Blog/Post';
import CheckoutV3 from './pages/CheckoutV3';
import ForgetPasswordStudent from './pages/ForgetPasswordStudent';
import Home from './pages/Home';
import HomeAdmin from './pages/HomeAdmin';
import About from './pages/HomeAdmin/pages/About';
import Instructors from './pages/HomeAdmin/pages/Instructors';
import { InstructorProfile } from './pages/HomeAdmin/pages/Instructors/InstructorProfile';
import ProductsPage from './pages/HomeAdmin/pages/ProductsPage';
import QuestionPage from './pages/HomeAdmin/pages/Question';
import InstructorSignUp from './pages/InstructorSignUp';
import InstructorLoading from './pages/InstructorSignUp/InstructorLoading';
import InstructorThanks from './pages/InstructorSignUp/InstructorThanks';
import { IntegrationForm } from './pages/InstructorSignUp/IntegrationForm';
import { LoadingIntegrationForm } from './pages/InstructorSignUp/IntegrationForm/Loading';
import LaunchCheckout from './pages/LaunchCheckout';
import BoletoGeneratedPage from './pages/LaunchCheckout/BoletoGeneratedPage';
import PixGeneratedPage from './pages/LaunchCheckout/PixGeneratedPage';
import LaunchCheckoutThanksPage from './pages/LaunchCheckout/ThanksPage';
import PreviewCertificate from './pages/PreviewCertificate';
import RecurrenceCheckout from './pages/RecurrenceCheckout';
import RecurrenceCheckoutThanksPage from './pages/RecurrenceCheckout/ThanksPage';
import RecurrencePlan from './pages/RecurrencePlan';
import Sales from './pages/Sales';
import SignUp from './pages/SignUp';
import SignUpAccount from './pages/SignUpAccount';
import StudentSignUp from './pages/StudentSignUp';
import SubscriptionCheckout from './pages/SubscriptionCheckoutStudent';
import BeginnerPlan from './pages/Terms/Plans/BeginnerPlan';
import PremiumPlan from './pages/Terms/Plans/PremiumPlan';
import ProfessionalPlan from './pages/Terms/Plans/ProfessionalPlan';
import Policies from './pages/Terms/Policies';
import PrivacyPolicy from './pages/Terms/PrivacyPolicy';
import StudentTerm from './pages/Terms/StudentTerm';
import TermUse from './pages/Terms/TermUse';
import Thanks from './pages/Thanks';
import TrialPeriodForm from './pages/TrialPeriodForm';
import TrialPeriodLoading from './pages/TrialPeriodLoading';
import { TrialPeriodRegistration } from './pages/TrialPeriodRegistration';
import ResetPasswordV2 from './pages/v2/Login/ResetPassword';
import SignInV2 from './pages/v2/Login/SignInV2';
import AdminRoutes from './routes/AdminRoutes';
import MembersAreaClassicRoutes from './routes/MembersAreaClassicRoutes';
import MembersAreaNetflixRoutes from './routes/MembersAreaNetflixRoutes';

export function IS_REACT_APP_HOSTNAME(hostname: string) {
  return (
    hostname === process.env?.REACT_APP_HOSTNAME2 || hostname === process.env?.REACT_APP_HOSTNAME
  );
}

export const hostname = window.location.hostname;

export const platformsWithoutVideoLibrary = [
  'paulotomazinho.proluno.com.br',
  'jessycacorrea.proluno.com.br',
  'carolmariath.proluno.com.br',
  'fernandafonseca.proluno.com.br',
  'juliasartini.proluno.com.br',
  'paulavasarini.proluno.com.br',
  'leticiarodrigues.proluno.com.br',
  'carolinamagon.proluno.com.br',
  'marcellemartins.proluno.com.br',
  'ivyaugusto.proluno.com.br',
  'louiza.proluno.com.br',
  'julianasarettam.proluno.com.br',
];

export const platformsToRemoveInstructorTransactionsMenu = ['professorsoares.com.br'];

export const platformsWithSubscription = [
  'professorsoares.com.br',
  'presets.liviabrasil.com.br',
  'ramon.proluno.com.br',
  'roniere.proluno.com.br',
  'velasquez.proluno.com.br',
  'local.proluno.com.br',
  'sandbox.proluno.com.br',
  'ensinedoseujeito.proluno.com.br',
  'profcacardoso.com.br',
  'alexandrapedinotti.com.br',
  'paulacfrocha.com.br',
  'esadsuperior.com',
  'sentidounico.proluno.com.br',
  'proficoncursos.proluno.com.br',
  'cursopreparar.com.br',
  'abtead.proluno.com.br',
  'mastermed.proluno.com.br',
  'ifbplay.com.br',
  'vallensina1.com.br',
  'grcursos.proluno.com.br',
  'litterartcursos.proluno.com.br',
  'profissaoempreendedora.com',
  'aluzdabiblia.live',
  'profbianco.proluno.com.br',
  'emasteredu.com.br',
  'professormarciodanilo.proluno.com.br',
  'profaneydjamorais.proluno.com.br',
  'firminoprepara.com.br',
  'voceaft.proluno.com.br',
  'uniedu.proluno.com.br',
  'alunosemescala.proluno.com.br',
  'conceitoplay.com.br',
  'sandbox-eudesjr.proluno.com.br',
  'cursos.vorticeconcursos.com.br',
  'viverdeestudos.proluno.com.br',
  'pollylyra.com',
  'cursos.liviabrasil.com.br',
  'sobrancelhadesejada.proluno.com.br',
  'inapionline.proluno.com.br',
  'rhskills.proluno.com.br',
  'aprenda.meubizu.com.br',
  'borbacursos.com.br',
  'academiadodinheiro.proluno.com.br',
];

export const platformsWithKnowMore = [
  'emasteredu.com.br',
  'divadobras.proluno.com.br',
  'smartlanguages.com.br',
  'preparatorioboinagaranca.online',
  'ediomais.com',
  'firminoprepara.com.br',
  'preparatorioboinagaranca.online',
  'ediomais.com',
  'profaneydjamorais.proluno.com.br',
  'alexandrapedinotti.com.br',
  'professorsoares.com.br',
  'pollylyra.com',
  'choiceshr.proluno.com.br',
  'cursos.vorticeconcursos.com.br',
  'questoes.qblitz.com.br',
  'borbacursos.com.br',
];

export const platformsWithExtendAccess = [
  'roniere.proluno.com.br',
  'velasquez.proluno.com.br',
  'professorsoares.com.br',
  'profcacardoso.com.br',
  'presets.liviabrasil.com.br',
  'ensinedoseujeito.proluno.com.br',
  'cursopreparar.com.br',
  'alunosemescala.proluno.com.br',
  'cursos.vorticeconcursos.com.br',
  'voceaft.proluno.com.br',
];

export const platformsWithoutUploadVideo = [
  // 'local.proluno.com.br',
  'professorgiovannydias.proluno.com.br',
];

export const PLATFORMS_WITH_UPLOAD_LIMITATION = [
  { url: 'educacionalonline.com.br', limit: 100 },
  { url: 'cursopreparar.com.br', limit: 250 },
  { url: 'academiadodinheiro.proluno.com.br', limit: 20 },
  { url: 'ifbplay.com.br', limit: 100 },
  { url: 'nvmcursos.proluno.com.br', limit: 100 },
  { url: 'yone.proluno.com.br', limit: 20 },
  { url: 'alinefreitas.proluno.com.br', limit: 20 },
  { url: 'danielateles.proluno.com.br', limit: 20 },
  { url: 'alinecostato.proluno.com.br', limit: 20 },
  { url: 'lorranavalentin.proluno.com.br', limit: 20 },
  { url: 'campinaconcursos.proluno.com.br', limit: 20 },
  { url: 'firminoprepara.com.br', limit: 50 },
  { url: 'paloma.proluno.com.br', limit: 20 },
];

export const platformsWithPrimaryColorButton = ['lumencursos.com.br'];

export const platformsWith200MbUploadLimit = [
  'dimensaoprocursos.com.br',
  'marina.proluno.com.br',
  'local.proluno.com.br',
];

export const platformsWithoutInstructorInfo = ['vorticeconcursos.com.br', 'cursopreparar.com.br'];

function App() {
  const bgColor = useColorModeValue('#FFFFFF', '#202123');
  const history = useHistory();

  const location = useLocation();

  const profile = localStorage.getItem(ROLE_LOCAL_STORAGE);

  const { getThemeMarketplace } = useTheme();

  const concatenateUTMToURL = useCallback(async () => {
    // Função para concatenar os valores dos parâmetros UTM na URL atual

    const queryParams = new URLSearchParams();

    // Obter os parâmetros existentes da URL e adicioná-los ao queryParams
    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.forEach((value, key) => {
      queryParams.set(key, value);
    });

    const utmDataLocalStorage = localStorage.getItem(UTM_LOCAL_STORAGE);

    if (!utmDataLocalStorage) return;

    const utmData: {
      utm_id?: string;
      utm_source?: string;
      utm_medium?: string;
      utm_campaign?: string;
      utm_term?: string;
      utm_content?: string;
    } = JSON.parse(utmDataLocalStorage);

    const { utm_id, utm_source, utm_medium, utm_campaign, utm_term, utm_content } = utmData;

    if (utm_id) queryParams.set('utm_source', utm_id);
    if (utm_source) queryParams.set('utm_source', utm_source);
    if (utm_medium) queryParams.set('utm_medium', utm_medium);
    if (utm_campaign) queryParams.set('utm_campaign', utm_campaign);
    if (utm_term) queryParams.set('utm_term', utm_term);
    if (utm_content) queryParams.set('utm_content', utm_content);

    const search = queryParams.toString();
    const fullPath = `${window.location.pathname}${search ? `?${search}` : ''}`;

    // Atualizar a URL com os valores dos parâmetros UTM concatenados
    if (fullPath !== window.location.pathname + window.location.search) {
      window.history.replaceState(null, '', fullPath);
    }
  }, []);

  useEffect(() => {
    // Chamar a função para concatenar os valores dos parâmetros UTM à URL quando o componente é montado
    concatenateUTMToURL();
  }, [location, history, concatenateUTMToURL]);

  useEffect(() => {
    if (location.pathname === '/') {
      getThemeMarketplace();
    }
  }, [getThemeMarketplace, location.pathname]);

  useEffect(() => {
    async function firstAccess() {
      if (profile === 'DONO') {
        const { data: getFirstAccess } = await Platform.getFirstAccess();
        if (
          !getFirstAccess.firstAccess &&
          location.pathname !== '/instructor-signup/thanks' &&
          location.pathname !== '/instructor-signup/integration-form/loading'
        ) {
          return history.push('/instructor-signup/integration-form');
        }
        localStorage.setItem(
          FIRST_ACCESS_LOCAL_STORAGE,
          JSON.stringify(getFirstAccess.firstAccess)
        );
      }
    }

    let firstAccessLocalStorage = localStorage.getItem(FIRST_ACCESS_LOCAL_STORAGE);

    if (!firstAccessLocalStorage) {
      firstAccess();
    }
  }, [history, location.pathname, profile]);

  useEffect(() => {
    async function getJivoChat() {
      try {
        const { data: jivoChatIntegrationData } = await JivoChatIntegration.index();

        if (jivoChatIntegrationData) {
          const scriptJivoChat = document.getElementById('scriptJivoChat');
          const tags = document.getElementsByTagName('jdiv');

          if (!scriptJivoChat) {
            const scriptJivoChat = document.createElement('script');
            scriptJivoChat.id = 'scriptJivoChat';

            scriptJivoChat.src = jivoChatIntegrationData.src;
            scriptJivoChat.async = true;
            document.body?.appendChild(scriptJivoChat);
          }

          if (!profile || profile === 'ALUNO') {
            tags[0].setAttribute('style', 'display: block !important;');
          }

          if (profile && profile === 'DONO') {
            tags[0].setAttribute('style', 'display: none !important;');
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    }
    getJivoChat();
  }, [profile]);

  function RedirectHome() {
    const hostname = window.location.hostname;

    if (profile !== 'ALUNO') localStorage.removeItem('chakra-ui-color-mode');

    if (IS_REACT_APP_HOSTNAME(hostname)) {
      return <Home />;
    }

    return <HomeAdmin />;
  }

  function VerifyPlatform() {
    const hostname = window.location.hostname;

    if (IS_REACT_APP_HOSTNAME(hostname)) {
      return <SignUp />;
    }

    return <StudentSignUp />;
  }

  function changeBackground(color: string) {
    document.getElementsByTagName('body').item(0).style.backgroundColor = color;
  }

  useEffect(() => {
    changeBackground(bgColor);
  }, [bgColor]);

  return (
    <>
      <ExternalScripts />
      <UTMProvider>
        <SearchContextProvider>
          <Switch>
            <Route path="/trial-period-registration" component={TrialPeriodRegistration} />
            <Route path="/trial-period-loading" component={TrialPeriodLoading} />
            <Route path="/trial-period-form" component={TrialPeriodForm} />

            <Route path="/instructor-loading" component={InstructorLoading} />
            <Route path="/not-found" exact component={NotFound} />
            <Route path="/" exact component={RedirectHome} />
            <Route exact path="/blog" component={Blog} />
            <Route exact path="/blog/:id" component={Post} />
            <Route path="/about" component={About} />
            <Route path="/question" component={QuestionPage} />
            <Route path="/termos-e-politicas" component={Policies} />
            <Route path="/all-products" component={ProductsPage} />
            <Route path="/login" component={SignInV2} />
            <Route path="/reset-password" component={ResetPasswordV2} />
            <Route exact path="/forget-password" component={ForgetPasswordStudent} />
            <Route path="/cadastre-se" component={() => <VerifyPlatform />} />
            <Route path="/student-signup" component={StudentSignUp} />
            <Route path="/signup" component={StudentSignUp} />
            <Route exact path="/sign-up/account-users" component={SignUpAccount} />
            <Route exact path="/instructor-signup" component={InstructorSignUp} />
            <Route exact path="/instructor-signup/integration-form" component={IntegrationForm} />
            <Route
              exact
              path="/instructor-signup/integration-form/loading"
              component={LoadingIntegrationForm}
            />

            <Route exact path="/custom-instructor-signup" component={InstructorSignUp} />

            <Route exact path="/instructor-signup/thanks" component={InstructorThanks} />

            <Route exact path="/product/:id" component={Sales} />

            <Route exact path="/recurrence-plan/:recurrencePlanId" component={RecurrencePlan} />

            <Route path="/product/:id/subscription-checkout" component={SubscriptionCheckout} />

            <Route path="/student-checkout/v2">
              <Redirect
                to={{
                  pathname: '/student-checkout/v3',
                  search: window.location.search,
                }}
              />
            </Route>

            <Route path="/student-checkout/v3" component={CheckoutV3} />

            <Route path="/launch-checkout/:productId" component={LaunchCheckout} />

            <Route path="/recurrence-checkout/:idRecurrencePlan" component={RecurrenceCheckout} />

            <Route
              path="/launch-checkout-thanks/payment-completed"
              component={LaunchCheckoutThanksPage}
            />

            <Route
              path="/launch-checkout-thanks/boleto-generated"
              component={BoletoGeneratedPage}
            />

            <Route path="/launch-checkout-thanks/pix-generated" component={PixGeneratedPage} />

            <Route
              path="/recurrence-checkout-thanks/payment-completed"
              component={RecurrenceCheckoutThanksPage}
            />

            <Route
              exact
              path="/certificate/:courseId/user/:userId/view"
              component={PreviewCertificate}
            />
            <Route exact path="/product/:id/extend" component={Sales} />
            <Route path="/student-checkout/thanks" component={Thanks} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/term-of-use" component={TermUse} />
            <Route path="/beginner-plan" component={BeginnerPlan} />
            <Route path="/professional-plan" component={ProfessionalPlan} />
            <Route path="/premium-plan" component={PremiumPlan} />

            <Route path="/all-instructors" component={Instructors} />

            <Route exact path="/internal-profile/:id/instructor" component={InstructorProfile} />

            <Route
              exact
              path="/members-area/v1/accept-term/:id/course/:courseUserId/course-user-id"
              component={StudentTerm}
            />
            <Route
              exact
              path="/members-area/v2/accept-term/:id/course/:courseUserId/course-user-id"
              component={StudentTerm}
            />

            <Route exact path="/members-area/v1*" component={MembersAreaClassicRoutes} />

            <Route exact path="/members-area/v2*" component={MembersAreaNetflixRoutes} />

            <Route path="/*" component={AdminRoutes} />
          </Switch>

          <ToastContainer
            position="bottom-right"
            hideProgressBar={true}
            autoClose={4000}
            theme="colored"
          />
        </SearchContextProvider>
      </UTMProvider>

      <WhatsAppButton />
      <ChatBase />
    </>
  );
}

export default App;
