import { Button, Flex, Heading, IconButton } from '@chakra-ui/react';
import { MdChevronLeft as ChevronLeftIcon, MdOutlineRemoveRedEye as EyeIcon } from 'react-icons/md';
import { Link } from 'react-router-dom';

interface CheckoutSettingsEditHeaderProps {
  productName: string;
  productId: number;
}

export default function CheckoutSettingsEditHeader({
  productName,
  productId,
}: CheckoutSettingsEditHeaderProps) {
  const checkoutLink = `/launch-checkout/${productId}/personal-data`;

  return (
    <Flex
      as="header"
      align="center"
      justify="space-between"
      bg="#202123"
      pl={1}
      pr="1.125rem"
      py={3}
      position="relative"
    >
      <Button
        display={{ base: 'none', xl: 'flex' }}
        as={Link}
        to="/checkout-settings"
        leftIcon={<ChevronLeftIcon size="1.5rem" />}
        color="white"
        colorScheme="black"
        title="Voltar para a página inicial"
        fontSize="sm"
      >
        Retornar
      </Button>

      <IconButton
        display={{ base: 'flex', xl: 'none' }}
        as={Link}
        to="/checkout-settings"
        variant="ghost"
        color="white"
        icon={<ChevronLeftIcon size="1.5rem" />}
        aria-label={''}
        justifyContent="center"
        alignItems="center"
      />

      <Heading
        as="h1"
        fontSize={{ base: 'md', xl: 'xl' }}
        fontWeight="bold"
        color="white"
        marginLeft="auto"
        marginRight="auto"
        marginBottom={0}
        position="absolute"
        left="50%"
        transform="translateX(-50%)"
        noOfLines={2}
        textAlign="center"
        maxWidth={{ base: '100%', xl: '40%' }}
      >
        {productName}
      </Heading>

      <IconButton
        display={{ base: 'flex', xl: 'none' }}
        as={Link}
        to={checkoutLink}
        variant="outline"
        color="white"
        icon={<EyeIcon />}
        aria-label={''}
      />

      <Button
        display={{ base: 'none', xl: 'flex' }}
        as={Link}
        to={checkoutLink}
        leftIcon={<EyeIcon size="0.88rem" />}
        color="white"
        colorScheme="black"
        title="Voltar para a página inicial"
        fontSize="sm"
        variant="outline"
      >
        Visualizar
      </Button>
    </Flex>
  );
}
